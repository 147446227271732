import { Component } from '@angular/core';

@Component({
  selector: 'app-carton-article',
  templateUrl: './carton-article.component.html',
  styleUrls: ['./carton-article.component.scss']
})
export class CartonArticleComponent {

}
