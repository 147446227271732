
<div class="activity-home-area-2" style="background-image: url(assets/img/categories/curage.jpg); background-position: top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="choice-home-content">
                    <h1>Curage et Démolition Intérieure</h1>
                    <p class="text-white">
                        DMT Recyclage vous accompagne dans vos projets de curage et démolition intérieure avec des services écoresponsables et de haute qualité.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">

    <section class="row g-0">
        <div class="col-md-6 bg-position-center bg-size-cover bg-secondary" style="min-height: 15rem; background-image: url(assets/img/categories/49d29e7e-8a4c-4f5e-b5c2-59a834d56203.png);"></div>
        <div class="col-md-6 px-3 px-md-5 py-5">
            <div class="mx-auto py-lg-5" style="max-width: 35rem;">
                <h2 class="h3 pb-3 section-title" style="color: black;"><strong>Curage Avant Rénovation</strong></h2>
                <p class="fs-sm pb-3 text-muted">
                    Chez DMT Recyclage, nous offrons des services complets de curage pour préparer vos espaces commerciaux à la rénovation. Notre expertise comprend :
                </p>
                <ul>
                    <li>
                        <strong>Vider les locaux :</strong> Nous retirons l'ensemble du mobilier existant, qu'il s'agisse de meubles de bureau ou de magasin, assurant un espace vide pour les travaux futurs.
                    </li>
                    <li>
                        <strong>Petites démolitions :</strong> Nous nous occupons de la démolition des cloisons en plâtre, du retrait des planchers (simples ou informatiques), et des faux plafonds.
                    </li>
                    <li>
                        <strong>Préparation des surfaces :</strong> Nos équipes nettoient et préparent les surfaces pour qu'elles soient prêtes à accueillir les nouveaux aménagements, garantissant un environnement optimal pour les équipes de rénovation.
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Row: Delivery-->
    <section class="row g-0">
        <div class="col-md-6 bg-position-center bg-size-cover bg-secondary order-md-2" style="min-height: 15rem; background-image: url(assets/img/categories/currage2.jpeg);"></div>
        <div class="col-md-6 px-3 px-md-5 py-5 order-md-1">
            <div class="mx-auto py-lg-5" style="max-width: 35rem;">
                <h2 class="h3 pb-3 section-title" style="color: black;">
                    Démantèlement de Matériel Industriel</h2>
                <p class="fs-sm pb-3 text-muted">
                    En plus du curage, DMT Recyclage excelle dans le démantèlement de gros équipements industriels. Nos services incluent :
                </p>
                <ul>
                    <li>

                    <strong>Démantèlement de machines : </strong> Nous procédons au démontage et à l'enlèvement sécurisé de grandes machines industrielles, incluant celles en production, en respectant les normes de sécurité et environnementales.
                    </li>
                    <li>

                    <strong>Climatiseurs et installations sur toits</strong> : Nous assurons le démontage des systèmes de climatisation installés sur les toits, ainsi que d'autres installations complexes ou volumineuses.
                    </li>
                    <li>

                    <strong>Recyclage et gestion des déchets </strong> : Tous les matériaux issus du démantèlement sont triés et gérés de manière responsable. Nous nous engageons à favoriser le recyclage et la valorisation des déchets pour minimiser l'impact environnemental et maximiser la réutilisation des ressources.
                    </li>
                </ul>
            </div>
        </div>
    </section>


    <section id="choisir-dmt">
        <h2 class="section-title">Pourquoi Choisir DMT Recyclage ?</h2>
        <p>

            DMT Recyclage est plus qu'un simple prestataire de services de curage et de démantèlement. Nous sommes des experts du recyclage, intégrant des processus de tri rigoureux et une gestion écologique des matériaux à chaque étape de nos interventions. Notre objectif est de garantir non seulement une préparation efficace de vos espaces pour la rénovation, mais aussi de contribuer activement à la réduction des déchets et à la protection de l'environnement.


        </p>
    </section>

    <section id="contact-curage">
        <h2 class="section-title">Contactez-Nous</h2>
        <p>
            Pour des services de curage et démolition écoresponsables, contactez DMT Recyclage pour un devis personnalisé.
        </p>
    </section>
</div>
