import { Component } from '@angular/core';

@Component({
  selector: 'app-plastique',
  templateUrl: './plastique.component.html',
  styleUrls: ['./plastique.component.scss']
})
export class PlastiqueComponent {

}
