<div class="three-banner-area">
    <div class="banner-shape">
        <img src="assets/img/home-three/banner-shape2.png" alt="Banner">
        <img src="assets/img/home-three/banner-shape3.png" alt="Banner">
    </div>

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content">
                            <span>
                                DMT Recyclage –
                            </span>
                            <h1 style="font-size: 52px;">Votre Solution de Gestion des Déchets en Île-de-France</h1>
                            <p>Optimisez votre gestion des déchets avec DMT Recyclage.</p>

                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" routerLink="/">Voir nos services <i class='bx bx-right-arrow-alt'></i></a>
                                </li>
                                <li>
                                    <a class="banner-play popup-youtube" href="https://www.youtube.com/watch?v=aqz-KE-bpKQ"><i class='bx bx-play'></i></a>
                                    <span>Voir notre presentation</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-5">
                        <div class="main-banner-form-wrap pt-5 mt-5" style="background-color: #BBBBBB ">
                            <h3 class="pt-5">Faites Votre Devis en Ligne en 3 Clics !</h3>


                            <form>
                                <div class="form-group">
                                    <label>Choisir votre secteur :</label>
                                    <select class="form-control">
                                        <option value="Benne">Chantier</option>
                                        <option value="Bac">Tertiaire</option>
                                    </select>
                                </div>



                                <div class="form-group">
                                    <label>Type de Contenant :</label>
                                    <select class="form-control">
                                        <option value="Benne">Benne</option>
                                        <option value="Bac">Bac</option>
                                        <option value="Roll">Roll</option>
                                        <option value="Caisse Palette">Caisse Palette</option>
                                        <option value="Vrac">Vrac : en M³</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label>Volume :</label>
                                    <select class="form-control">
                                        <option>10m³</option>
                                        <option>15m³</option>
                                        <option>20m³</option>
                                        <option>30m³</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label>Matière à Collecter :</label>
                                    <select class="form-control">
                                        <option>Papier et Carton</option>
                                        <option>Plastique PET et Canettes</option>
                                        <option>Bio-déchets</option>
                                        <option>Verre</option>
                                        <option>Déchets Industriels Banals (DIB)</option>
                                        <option>Bois</option>
                                        <option>Ferraille</option>
                                        <option>Autres (préciser)</option>
                                    </select>
                                </div>
                                <a routerLink="/faire-une-collecte"  class="main-default-btn">Obtenez Votre Devis Gratuit</a>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="about-style-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-image">
                    <img src="assets/img/qsnous.webp" alt="image">

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-style-content">
                    <h3>Pourquoi Choisir DMT Recyclage ?</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-md-12">
                            <div class="about-style-content">
                                <p>
                                    <strong>Services Adaptés à Vos Besoins :</strong> <br>
                                    Que vous ayez besoin de bennes, bacs, rolls ou caisses palettes, nous avons la solution adaptée pour tous vos besoins de gestion des déchets.</p>
                            </div>
                            <div class="about-style-content">
                                <p><strong>Expertise dans les 5 Flux :</strong> <br> Nous gérons efficacement les flux de papier et carton, plastique PET, bio-déchets, verre et DIB, assurant une valorisation maximale de vos matières recyclables.</p>
                            </div>
                            <div class="about-style-content">

                                <p> <strong>Traçabilité et Qualité de Service :</strong> <br>Suivez chaque étape de la collecte et du traitement de vos déchets grâce à notre système de traçabilité en temps réel.</p>
                            </div>
                            <div class="about-style-content">
                                <p><strong>Zone d'Intervention :</strong> <br>Nos services sont disponibles dans toute l'Île-de-France, garantissant une collecte rapide et efficace où que vous soyez.</p>
                            </div>
                            <div class="about-style-content">
                                <p> <strong>Engagement Zéro Déchet :</strong> <br>Nous nous engageons à vous accompagner vers une gestion des déchets plus responsable, avec un objectif de réduction maximale des déchets résiduels.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="buying-home-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-5 col-md-12">
                <div class="buying-home-image">
                    <img src="assets/img/more-home/buying-home/buying-home.jpg" alt="image">

                    <div class="counter-box">
                        <span>DMT</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="buying-home-content">
                    <h3>Comment Ça Marche ?</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Remplissez le Formulaire de Devis</h4>
                                <p> En quelques clics, complétez les informations nécessaires pour obtenir votre devis personnalisé.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4> Recevez Votre Devis</h4>
                                <p>   Recevez immédiatement une estimation détaillée de nos services adaptés à vos besoins.

                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Planifiez Votre Collecte </h4>
                                <p>   Une fois le devis accepté, planifiez la date de collecte qui vous convient.

                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Profitez de Nos Services </h4>
                                <p>   Laissez-nous gérer la collecte et le traitement de vos déchets en toute sérénité.

                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
