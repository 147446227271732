import { ViewportScroller } from "@angular/common";
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
    @ViewChild("popup") popup: ElementRef;
    @ViewChild('myVideo') myVideo: ElementRef;
    fragment: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private viewportScroller: ViewportScroller
    ) {}

    contanctUs() {
        this.popup?.nativeElement.click();
        this.router.navigate(["/contact"]);
    }

    scrollTo(element: string): void {
        this.viewportScroller.scrollToAnchor(element);
    }

    ngOnInit(): void {
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
            this.selectTabAndScroll(fragment);
        });
    }

    selectTabAndScroll(fragment: string): void {
        if (fragment) {
            let tabId: string;
            switch(fragment) {
                case 'about':
                    tabId = 'pills-about-tab';
                    break;
                case 'values':
                    tabId = 'pills-home-tab';
                    break;
                case 'mission':
                    tabId = 'pills-profile-tab';
                    break;
                default:
                    tabId = 'pills-about-tab';
            }
            const tabElement = document.getElementById(tabId);
            if (tabElement) {
                tabElement.click();
                setTimeout(() => {
                    const yOffset = -170; // Ajustez cette valeur en fonction de la hauteur de votre barre de navigation
                    const element = document.getElementById(fragment);
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                }, 300); // Ajout d'un délai pour permettre le changement d'onglet avant de faire défiler
            }
        }
    }

    ngAfterViewInit() {
        this.myVideo.nativeElement.muted = true;
        const menuLinks = document.querySelectorAll('.nav-item .dropdown-menu .dropdown-item.pointer');
        menuLinks.forEach(link => {
            link.addEventListener('mouseover', (event) => {
                const target = (event.target as HTMLAnchorElement).getAttribute('fragment');
                if (target) {
                    this.scrollTo(target);
                }
            });
        });
    }
}
