<div class="materiel-propose-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="choice-home-content">
                    <h1>Materiel Proposé</h1>
                    <p class="text-white">
                        DMT Recyclage est une entreprise spécialisée dans la
                        collecte et le recyclage des déchets.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="services" class="one-service-area ptb-100">
    <div class="container">

        <nav>
            <div id="nav-tab" role="tablist" class="nav nav-tabs">
                <a
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    href="#nav-home"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                    class="nav-item nav-default active"
                >
                    <div class="service-item">
                        <img
                            src="/assets/img/materielprpose/Plan_de_travail_1.webp"
                            alt="image" />
                        <span>Bac de Déchets Plastique</span>
                    </div>
                </a>
                <a
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    href="#nav-profile"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                    class="nav-item nav-default"
                    tabindex="-1"
                >
                    <div class="service-item">
                        <img
                            src="/assets/img/materielprpose/Plan_de_travail_1_copie.webp"
                            alt="image" />
                        <span>Caisse Palette Plastique 660L</span>
                    </div>
                </a>
                <a
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    href="#nav-contact"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                    class="nav-item nav-default"
                    tabindex="-1"
                >
                    <div class="service-item">
                        <img
                            src="/assets/img/materielprpose/Plan_de_travail_1_copie_2.webp"
                            alt="image" />
                        <span>Roll Métallique 1m³ </span>
                    </div>
                </a>
                <a
                    id="nav-x-tab"
                    data-bs-toggle="tab"
                    href="#nav-x"
                    role="tab"
                    aria-controls="nav-x"
                    aria-selected="false"
                    class="nav-item nav-default"
                    tabindex="-1"
                >
                    <div class="service-item">
                        <img
                            src="/assets/img/bennee-dmt.png"
                            alt="image" />
                        <span>Benne de 10, 15 et 20m³</span>
                    </div>
                </a>
                <a
                    id="nav-y-tab"
                    data-bs-toggle="tab"
                    href="#nav-y"
                    role="tab"
                    aria-controls="nav-y"
                    aria-selected="false"
                    class="nav-item nav-default"
                    tabindex="-1"
                >
                    <div class="service-item">
                        <img
                            src="/assets/img/materielprpose/Plan_de_travail_1_copie_4.webp"
                            alt="image" />
                        <span>Points d'Apport Volontaire (PAV)</span>
                    </div>
                </a>
                <a
                    id="nav-z-tab"
                    data-bs-toggle="tab"
                    href="#nav-z"
                    role="tab"
                    aria-controls="nav-z"
                    aria-selected="false"
                    class="nav-item nav-default"
                    tabindex="-1"
                >
                    <div class="service-item">
                        <img
                            src="/assets/img/materielprpose/Plan_de_travail_1_copie_5.webp"
                            alt="image" />
                        <span>Bioseau pour Tri des Déchets Organiques</span>
                    </div>
                </a>
            </div>
        </nav>
        <div id="nav-tabContent" class="tab-content">
            <div
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                class="tab-pane fade show active"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/materielprpose/Plan_de_travail_1.webp"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Bac de Déchets Plastique : Solution de Stockage Polyvalente</h2>
                                <p>
                                    Le bac de déchets plastique offre une solution robuste et polyvalente pour le stockage des déchets dans divers environnements professionnels. Disponible en volumes de <strong>240</strong>, <strong>340</strong>, <strong>660</strong>, <strong>750</strong> et <strong>1000</strong> litres, ce bac est adapté pour une utilisation dans les immeubles de bureaux, les entrepôts industriels et sur les chantiers de construction. Il permet de stocker une variété de déchets, tels que :
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Les déchets industriels banals (DIB)
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Le carton
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Le plastique et le verre
                                    </li>
                                </ul>
                                <p>
                                    grâce à son design ergonomique et à ses couvercles de différentes couleurs facilitant le tri sélectif
                                </p>
                                <h4>Services Associés :</h4>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Option avec Timon pour une Mobilité Optimale : Optez pour l'option avec timon pour une facilité de déplacement accrue du bac sur votre site, offrant ainsi une flexibilité maximale dans votre processus de gestion des déchets.

                                    </li>
                                </ul>
                                <div class="col-lg-5">
                                    <a routerLink="/contact"
                                       fragment="contant-form"
                                       class="cmn-banner-btn d-block mt-3 btn-success btn-sm"><i
                                        class="bx bx-note"></i>Demander un devis</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/materielprpose/Plan_de_travail_1_copie.webp"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Caisse Palette Plastique 660 Litres : Stockage Polyvalent pour Divers Flux de Déchets</h2>
                                <p>La caisse palette plastique de 660 litres offre une solution robuste et polyvalente pour le stockage et le transport efficace de divers flux de déchets. Adaptée pour une utilisation dans les immeubles de bureaux,Les entrepôts industriels et Les chantiers de construction.</p>
                                <ul>
                                    <li>
                                        <ul>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Les déchets d'équipements électriques et électroniques (DEEE)</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Le carton</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Les encombrants</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Le papier</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Les sacs</li>
                                            <li>
                                                <i class="bx bxs-chevrons-right"></i>
                                                Archives confidentielles ou conventionnelles</li>
                                        </ul>
                                    </li>
                                    <li>Son design ergonomique permet une manipulation aisée au transpalette et offre la possibilité de gerber les caisses pour optimiser l'espace de stockage.</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/materielprpose/Plan_de_travail_1_copie_2.webp"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Roll Métallique 1m³ : Solution Polyvalente pour le Stockage et le Transport de Déchets en Volume</h2>
                                <p>Le roll métallique de 1m³ est conçu pour offrir une solution polyvalente et ergonomique de stockage et de transport pour les déchets volumineux, optimisant ainsi l'efficacité logistique sur vos sites de production ou de traitement.</p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Déchets industriels banals (DIB)
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Matières recyclables légères
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Chutes de production
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-x"
                role="tabpanel"
                aria-labelledby="nav-x-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/bennee-dmt.png"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Benne de 10, 15 et 20m³ : Transport des Déchets en Quantités Importantes</h2>
                                <p>Les bennes de 10, 15 et 20m³ offrent une solution idéale pour le transport des déchets en grandes quantités, que ce soit sur les chantiers, dans les zones industrielles ou pour des opérations de collecte spécifiques. Elles sont particulièrement adaptées pour :</p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Déchets industriels banals (DIB)
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Gravats
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Déchets de démolition
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-y"
                role="tabpanel"
                aria-labelledby="nav-y-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/materielprpose/Plan_de_travail_1_copie_4.webp"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Points d'Apport Volontaire (PAV) : Collecte Systématique pour un Recyclage Optimal</h2>
                                <p>Les points d'apport volontaire sont des dispositifs fixes ou mobiles permettant aux usagers de déposer leurs déchets dans des conteneurs dédiés. Ces points de collecte jouent un rôle crucial dans la chaîne du recyclage, en assurant une séparation efficace des flux de déchets à la source.</p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Déchets recyclables (papier, carton, verre, plastique)
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Déchets organiques
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Textiles
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Déchets spécifiques (piles, batteries, etc.)
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="nav-z"
                role="tabpanel"
                aria-labelledby="nav-z-tab"
                class="tab-pane fade"
            >
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/materielprpose/Plan_de_travail_1_copie_5.webp"
                                    alt="Advisor"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Bioseau pour Tri des Déchets Organiques : Solution Ergonomique pour une Gestion Optimisée des Biodéchets</h2>
                                <p>Le bioseau est un équipement essentiel pour le tri et le stockage des déchets organiques dans le cadre d'un processus de compostage ou de valorisation. Conçu pour une utilisation pratique en cuisine ou dans des environnements commerciaux, il est adapté pour :</p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Déchets alimentaires
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Matières compostables
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Déchets verts
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
