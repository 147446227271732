<div class="headervideo pb-0">
    <div class="overlay"></div>
    <video #myVideo playsinline autoplay controls loop class="pb-0" muted>
        <source src="assets/img/dmtvideo.mp4" type="video/mp4">
    </video>

    <div class="container h-100">
        <div class="d-flex h-100 text-center align-items-center">
            <div class="w-100  text-black">
                <h4 class="display-6 text-start"></h4>
                <p class="lead mb-0 text-start"></p>
                <p class="mt-2 text-start align-text-bottom ">
                </p>
            </div>
        </div>
    </div>
</div>

<div  id="blog" class="one-blog-area pt-5 pb-70 messervices">
    <div  class="container" id="services-section">
        <div class="main-section-title">
            <h2>Nos services</h2>
        </div>

        <div  class="row">
            <div  class="col-sm-6 col-lg-4">
                <div  class="blog-item">
                    <div  class="blog-top">
                        <a  routerLink="/services" fragment="collecte_et_recyclage">
                            <img  src="assets/img/categories/cr.jpg" alt="Blog">
                        </a>
                    </div>
                </div>
            </div>


            <div  class="col-sm-6 col-lg-4">
                <div  class="blog-item">
                    <div  class="blog-top">
                        <a  routerLink="/services" fragment="bio_dechets">
                            <img  src="assets/img/categories/bio.jpg" alt="Blog"></a>
                    </div>
                </div>
            </div>

            <div  class="col-sm-6 col-lg-4">
                <div  class="blog-item">
                    <div  class="blog-top">
                        <a  routerLink="/services" fragment="debarras">
                            <img  src="assets/img/categories/debarras.jpg" alt="Blog"></a>
                    </div>
                </div>

            </div>

            <div  class="col-sm-6 col-lg-4">
                <div  class="blog-item">
                    <div  class="blog-top">
                        <a  routerLink="/services" fragment="demantelement">
                            <img  src="assets/img/categories/demantelement.jpg" alt="Blog"></a>
                    </div>

                </div>
            </div>

            <div  class="col-sm-6 col-lg-4">
                <div  class="blog-item">
                    <div  class="blog-top">
                        <a  routerLink="/curage" >
                            <img  src="assets/img/categories/curage.jpg" alt="Blog"></a>
                    </div>
                </div>
            </div>

            <div  class="col-sm-6 col-lg-4">
                <div  class="blog-item">
                    <div  class="blog-top">
                        <a  routerLink="/services" fragment="demantelement">
                            <img  src="assets/img/categories/chantier.jpg" alt="Blog"></a>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <span id="about"></span>
</div>


<div class="about-style-area">
    <div   class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-style-image">
                    <img
                        src="/assets/img/qsnous.webp"
                        alt="image" />
                    <div class="quote-content">
                        <span>DMT Recyclage</span>
                        <p>
                            “Votre Partenaire de Collecte et de Transition Vers
                            un Avenir Zéro Déchet”
                        </p>
                    </div>
                </div>
            </div>

            <div id="valor" class="col-lg-7 col-md-12">
                <div class="about-style-content">
                    <span class="sub-title"></span>
                    <h3>A propos</h3>

                    <div class="three-about-area">
                        <div class="about-content">
                            <div class="about-mission">
                                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-default active" id="pills-about-tab" data-bs-toggle="pill" href="#pills-about" role="tab" aria-controls="pills-home" aria-selected="true">
                                            <i class="bx bx-bullseye"></i>
                                            Qui sommes-nous?
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-default" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                                            <i class="bx bx-bullseye"></i>
                                            Nos Valeurs
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-default" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
                                            <i class="bx bx-show-alt"></i>
                                            Notre Mission
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                                        <p id="about">
                                            DMT Recyclage : Votre Partenaire pour une Entreprise Zéro Déchet.
                                        </p>
                                        <ul>
                                            <li>
                                                Chez DMT Recyclage, nous incarnons l'engagement en faveur d'un avenir durable. En tant que votre partenaire de confiance, nous vous accompagnons dans votre transition vers une entreprise plus écologique et responsable. Notre mission est de vous aider à réduire votre empreinte environnementale en adoptant des pratiques durables et en favorisant le recyclage.
                                            </li>
                                            <li>
                                                <i class="bx bx-chevrons-right"></i>
                                                <b>Notre approche</b> <br>
                                                Nous mettons à votre disposition notre expertise en gestion des déchets pour vous fournir des solutions personnalisées, adaptées à vos besoins spécifiques. Que ce soit par la collecte de matières valorisables ou par l'intégration de pratiques durables dans vos opérations quotidiennes, nous sommes là pour vous guider à chaque étape de votre parcours vers la durabilité.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <p id="values">
                                            Nos Valeurs: Agir pour un Avenir Durable
                                        </p>
                                        <ul>
                                            <li>
                                                <i class="bx bx-chevrons-right"></i>
                                                <b>Responsabilité Environnementale </b>
                                                <br />
                                                Nous nous engageons à préserver l'environnement en adoptant des pratiques durables et en minimisant notre impact écologique.
                                            </li>
                                            <li>
                                                <i class="bx bx-chevrons-right"></i>
                                                <b>Innovation Continue</b>
                                                <br />
                                                Toujours à la pointe, nous utilisons les dernières technologies pour offrir des solutions de gestion des déchets efficaces et durables.
                                            </li>
                                            <li>
                                                <i class="bx bx-chevrons-right"></i>
                                                <b>Transparence Totale</b><br />
                                                La confiance est au cœur de notre relation avec nos clients. Nous assurons une visibilité totale sur nos opérations, de la collecte au traitement.
                                            </li>
                                            <li>
                                                <i class="bx bx-chevrons-right"></i>
                                                <b>Actions à Impact</b><br />
                                                Nous réduisons activement l'empreinte environnementale de nos clients en favorisant le recyclage et en intégrant des pratiques durables dans leur quotidien opérationnel.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <p id="mission">
                                            Notre mission principale est de promouvoir des pratiques durables dans la gestion des déchets, en minimisant l'impact environnemental et en favorisant le recyclage.
                                        </p>
                                        <ul>
                                            <li>
                                                <i class="bx bx-chevrons-right"></i>
                                                <b>Viser l'Excellence et l'Innovation</b><br />
                                                Nous nous engageons à atteindre l'excellence dans nos opérations en adoptant des approches innovantes et en utilisant les dernières technologies disponibles.
                                            </li>
                                            <li>
                                                <i class="bx bx-chevrons-right"></i>
                                                <b>Répondre aux Besoins de nos Clients</b>
                                                <br />
                                                Nous visons à répondre aux besoins spécifiques de nos clients en offrant des solutions sur mesure, transparentes et efficaces pour la gestion de leurs déchets.
                                            </li>
                                            <li>
                                                <i class="bx bx-chevrons-right"></i>
                                                <b>Poursuivre les Certifications et les Standards Élevés</b>
                                                <br />Nous avons pour ambition de devenir une entreprise à mission, en nous engageant à respecter les normes les plus élevées du marché et en obtenant les certifications pertinentes pour garantir la qualité et la fiabilité de nos services.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="process-style-area ptb-100" id="engagements" #engagements>
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Notre Engagement Environnemental</span>
            <h3>DMT Recyclage, Aller Au-delà de la Collecte Traditionnelle</h3>
            <p class="text-dark">
                Chez DMT Recyclage, nous transcendons le rôle traditionnel de
                collecteur de déchets pour répondre aux exigences modernes de
                notre époque et aux impératifs environnementaux. Notre modèle
                s'inscrit dans une approche proactive de réduction des déchets,
                guidée par les principes des 3R :
                <i>Réduire, Réutiliser, Recycler</i>
            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="process-style-accordion">
                    <div class="accordion" id="ProcessAccordion">
                        <div class="accordion-item">
                            <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne">
                                <span>01</span> Réduire
                            </button>
                            <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                data-bs-parent="#ProcessAccordion">
                                <div class="accordion-body">
                                    <p class="bold">
                                        Nous nous engageons à travailler en
                                        étroite collaboration avec nos clients
                                        pour réduire la génération de déchets à
                                        la source. En repensant les processus et
                                        en introduisant des solutions
                                        innovantes, nous visons à minimiser
                                        l'empreinte déchets de nos partenaires.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo">
                                <span>02</span> Réutiliser
                            </button>
                            <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                data-bs-parent="#ProcessAccordion">
                                <div class="accordion-body">
                                    <p class="bold">
                                        DMT encourage la réutilisation des
                                        matériaux autant que possible. Nous
                                        soutenons nos clients dans l'adoption de
                                        pratiques de réutilisation, favorisant
                                        ainsi une gestion durable des ressources
                                        et une réduction significative du
                                        gaspillage.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree">
                                <span>03</span> Recycler
                            </button>
                            <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                data-bs-parent="#ProcessAccordion">
                                <div class="accordion-body">
                                    <p class="bold">
                                        Au cœur de notre mission se trouve le
                                        recyclage. Nous opérons des centres de
                                        collecte sélective et de tri de pointe,
                                        permettant une valorisation maximale des
                                        matières recyclables. Notre objectif est
                                        de donner une seconde vie aux matériaux,
                                        contribuant ainsi à la préservation des
                                        ressources naturelles.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="process-style-image rounded rounded-3">
                    <img src="assets/img/reduire.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</div>


<div id="zeroDechet" class="core-service-area pt-5" #zeroDechet>
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Vous Êtes?</span>
            <h2>Des Solutions personnalisées pour tous</h2>
        </div>

        <div class="core-service-list-tab">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" id="you_are_1">
                    <a
                        class="nav-link active"
                        id="core-service-1-tab"
                        data-bs-toggle="tab"
                        href="#core-service-1"
                        role="tab"
                        aria-controls="core-service-1">Professionnel</a>
                </li>

                <li class="nav-item" id="you_are_2">
                    <a
                        class="nav-link"
                        id="core-service-2-tab"
                        data-bs-toggle="tab"
                        href="#core-service-2"
                        role="tab"
                        aria-controls="core-service-2">Collectivités/Syndics</a>
                </li>

                <li class="nav-item" id="you_are_3">
                    <a
                        class="nav-link"
                        id="core-service-3-tab"
                        data-bs-toggle="tab"
                        href="#core-service-3"
                        role="tab"
                        aria-controls="core-service-3">Particulier</a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div
                    class="tab-pane fade show active"
                    id="core-service-1"
                    role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-building-house"></i>
                                </div>
                                <h3>Professionnel</h3>
                                <p>
                                    Optimisez Votre Chaîne d'Approvisionnement
                                    Écologique.
                                </p>
                                <ul class="list">
                                    <li>
                                        <i class="bx bx-chevrons-right"></i> De
                                        l'enlèvement au traitement, nous gérons
                                        toute votre logistique de déchets.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i> Un
                                        accompagnement expert pour répondre à
                                        vos besoins spécifiques.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i> Des
                                        solutions adaptables selon l'évolution
                                        de vos besoins.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Suivez le parcours de vos déchets avec
                                        nos systèmes de traçabilité avancés.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/img_23.png" alt="image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="core-service-2" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-dollar-circle"></i>
                                </div>
                                <h3>Collectivités/Syndics</h3>
                                <p>
                                    Gestion Intégrée pour un Environnement
                                    Durable
                                </p>
                                <ul class="list">
                                    <li>
                                        <i class="bx bx-chevrons-right"></i> Une
                                        relation à long terme avec des solutions
                                        adaptées aux structures collectives.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Enlèvement, transport, et traitement,
                                        couvrant l'ensemble de vos besoins.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Mise en conformité avec les
                                        réglementations environnementales.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i> Des
                                        rapports détaillés pour une gestion
                                        transparente des déchets.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/img_24.png" alt="image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="core-service-3" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-home-circle"></i>
                                </div>
                                <h3>Particulier</h3>
                                <p>
                                    Des Solutions Personnalisées pour un Avenir
                                    Plus Propre
                                </p>
                                <ul class="list">
                                    <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Adaptabilité aux besoins, assurant votre
                                        entière satisfaction.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Contact direct avec un interlocuteur
                                        professionnel et disponible.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Prix attractifs, souplesse
                                        d'organisation, et réactivité
                                        remarquable.
                                    </li>
                                    <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Engagement pour une gestion optimale de
                                        vos déchets.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/img_25.png" alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <span id="temoignages"></span>
</div>
<br />
<section class="two-lover-area five-lover-area six-lover-area pb-100">
    <div class="container-fluid p-0">
        <div class="one-section-title three-section-title">
            <span class="sub-title" >
                Témoignages</span>
            <h2>Nos clients satisfaits</h2>
        </div>

        <div class="six-lover-slider owl-theme owl-carousel">
            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Sophie L.</h3>
                            <span>Professionnelle dans le Secteur de la
                                Restauration</span>
                            <p>
                                DMT Recyclage a transformé notre approche de la
                                gestion des déchets. Leur équipe dédiée a su
                                répondre à nos besoins spécifiques avec des
                                solutions sur-mesure, contribuant ainsi à rendre
                                notre entreprise plus éco-responsable
                            </p>
                            <div class="lover-icon">
                                <i class="bx bxs-quote-left"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Jean-Pierre M.</h3>
                            <span>Particulier</span>
                            <p>
                                En tant que particulier, j'apprécie la facilité
                                avec laquelle DMT Recyclage gère mes déchets.
                                Leur service de collecte à la demande est un
                                véritable atout, me permettant de contribuer
                                activement au recyclage sans tracas.
                            </p>
                            <div class="lover-icon">
                                <i class="bx bxs-quote-left"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Sanaik Tubi</h3>
                            <span>Furnishhome, Internation</span>
                            <p>
                                DMT Recyclage est devenu un partenaire
                                incontournable pour notre syndic. Leur gestion
                                intégrée des déchets, respectueuse des normes
                                environnementales, simplifie notre quotidien et
                                renforce notre engagement envers la durabilité.
                            </p>
                            <div class="lover-icon">
                                <i class="bx bxs-quote-left"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>


<div class="why-choose-us-area" id="references" #references>
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Pourquoi Nous Choisir</span>
            <h2>
                La confiance de nos clients est notre plus grande récompense
            </h2>
        </div>

        <div class="why-choose-us-slider owl-carousel owl-theme">
            <div class="why-choose-us-card h-100">
                <div class="choose-image">
                    <img
                        src="assets/img/partenaires/img/1.png"
                        alt="image"
                        class="h-100" />
                </div>
            </div>

            <div class="why-choose-us-card h-100">
                <div class="choose-image">
                    <img src="assets/img/partenaires/img/2.png" alt="image" />
                </div>
            </div>

            <div class="why-choose-us-card h-100">
                <div class="choose-image">
                    <img src="assets/img/partenaires/img/3.png" alt="image" />
                </div>
            </div>

            <div class="why-choose-us-card h-100">
                <div class="choose-image">
                    <img src="assets/img/partenaires/img/4.png" alt="image" />
                </div>
            </div>

            <div class="why-choose-us-card h-100">
                <div class="choose-image">
                    <img src="assets/img/partenaires/img/5.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Button trigger modal -->
<button
    #popup
    type="button"
    class="poppup"
    data-bs-toggle="modal"
    data-bs-target="#staticBackdrop"></button>

<!-- Modal -->
<div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body" (click)="contanctUs()">
                <img src="assets/popup.jpg" alt srcset />
            </div>
        </div>
    </div>
</div>
