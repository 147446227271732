import { ViewportScroller } from "@angular/common";
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-materiel-propose",
    templateUrl: "./materiel-propose.component.html",
    styleUrls: ["./materiel-propose.component.scss"],
})
export class MaterielProposeComponent implements OnInit, AfterViewInit {
    @ViewChild("popup") popup: ElementRef;
    fragment: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private viewportScroller: ViewportScroller
    ) {}

    ngOnInit(): void {
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
            this.selectTabAndScroll(fragment);
        });
    }

    selectTabAndScroll(fragment: string): void {
        if (fragment) {
            const tabElement = document.querySelector(`[href='#${fragment}']`);
            if (tabElement) {
                (tabElement as HTMLElement).click();
                setTimeout(() => {
                    const yOffset = -500; // Ajustez selon la hauteur de votre barre de navigation
                    const element = document.getElementById(fragment);
                    if (element) {
                        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }
                }, 300); // Délai pour permettre le changement d'onglet avant de défiler
            }
        }
    }

    ngAfterViewInit() {
        const menuLinks = document.querySelectorAll('.nav-item.nav-default');
        menuLinks.forEach(link => {
            link.addEventListener('click', (event) => {
                const target = (event.currentTarget as HTMLAnchorElement).getAttribute('href')?.substring(1);
                if (target) {
                    this.selectTabAndScroll(target);
                }
            });
        });
    }
}
