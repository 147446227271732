<div class="activity-home-area-2" style="background-image: url(assets/img/plastique.jpg);">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choice-home-content">
                    <h1>Plastique</h1>
                    <p class="text-white">
                        DMT Recyclage est une entreprise spécialisée dans la
                        collecte et le recyclage des déchets.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">
    <!-- Qu'est-ce que le plastique ? -->
    <section id="plastique">
        <h2 class="section-title">♻️ Qu'est-ce que le plastique ?</h2>
        <p>Le plastique est un matériau synthétique omniprésent, largement utilisé pour ses propriétés de légèreté, de durabilité et de coût efficace. Les plastiques sont classés selon des codes d'identification normalisés qui aident au tri et au recyclage :</p>

        <div class="row">
            <!-- PET -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">PET (Polyéthylène Téréphtalate) - Code 1</h5>
                        <p class="card-text">Principalement utilisé pour les bouteilles et les emballages alimentaires. Le PET est transparent et recyclable, ce qui en fait un choix populaire dans le secteur tertiaire.</p>
                    </div>
                </div>
            </div>
            <!-- HDPE -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">HDPE (Polyéthylène Haute Densité) - Code 2</h5>
                        <p class="card-text">Utilisé pour les contenants rigides tels que les bidons de détergent. Ce plastique est robuste et résistant aux produits chimiques.</p>
                    </div>
                </div>
            </div>
            <!-- PVC -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">PVC (Polychlorure de Vinyle) - Code 3</h5>
                        <p class="card-text">Connu pour sa résistance et sa flexibilité. Cependant, le PVC peut libérer des toxines lorsqu'il est brûlé, ce qui le rend moins favorable pour le recyclage.</p>
                    </div>
                </div>
            </div>
            <!-- LDPE -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">LDPE (Polyéthylène Basse Densité) - Code 4</h5>
                        <p class="card-text">Utilisé dans les sacs en plastique et les films d'emballage. Bien que moins dense, le LDPE est recyclable et très répandu.</p>
                    </div>
                </div>
            </div>
            <!-- PP -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">PP (Polypropylène) - Code 5</h5>
                        <p class="card-text">Utilisé pour les emballages alimentaires et les pièces automobiles. Ce plastique est résistant à la chaleur et couramment recyclé.</p>
                    </div>
                </div>
            </div>
            <!-- PS -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">PS (Polystyrène) - Code 6</h5>
                        <p class="card-text">Utilisé pour les emballages alimentaires et les produits jetables. Le polystyrène est recyclable, mais pose des défis en matière de gestion des déchets.</p>
                    </div>
                </div>
            </div>
            <!-- Code 7 -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Autres plastiques - Code 7</h5>
                        <p class="card-text">Un mélange de divers plastiques, souvent difficiles à recycler. Cela inclut des matériaux comme le polycarbonate et le bioplastique.</p>
                    </div>
                </div>
            </div>
        </div>

        <p>Ces codes d'identification permettent aux entreprises de mieux trier les plastiques pour le recyclage, assurant ainsi un traitement approprié des déchets plastiques.</p>

        <h2 class="section-title">🏢 Pourquoi le plastique est-il utilisé ?</h2>
        <p>Dans le secteur tertiaire, le plastique est privilégié pour sa capacité à protéger les produits, sa légèreté et sa facilité d'utilisation. Il est utilisé dans des applications telles que l'emballage, le stockage, et la distribution de fournitures de bureau, ce qui en fait un matériau indispensable dans les environnements d'affaires.</p>

        <h2 class="section-title">📊 Quantité et sources de plastique dans l'entreprise</h2>
        <p>Les entreprises du secteur tertiaire génèrent principalement des déchets plastiques sous forme de PET, notamment à travers les bouteilles d'eau et les emballages alimentaires. La gestion de ces déchets est essentielle pour réduire l'impact environnemental et optimiser les processus de recyclage.</p>

        <h2 class="section-title">🗑️ Moyens de stockage et de collecte du plastique</h2>
        <p>DMT Recyclage recommande l'installation de points d'apport volontaire au sein des entreprises pour un tri efficace des plastiques. Les rolls de 1,5 m³ et les bacs de 660 litres sont idéaux pour le stockage temporaire, facilitant ainsi le tri à la source et optimisant l'espace.</p>

        <h2 class="section-title">🚛 Procédure de collecte</h2>
        <p>Nos équipes planifient des collectes régulières pour assurer une gestion fluide des déchets plastiques. Grâce à notre flotte de camions modernes, nous récupérons les plastiques triés avec une efficacité maximale, même dans des environnements urbains denses.</p>

        <h2 class="section-title">🔄 Systèmes de tri et préparation au recyclage</h2>
        <p>Le tri en amont est crucial pour maximiser la recyclabilité des plastiques. DMT Recyclage utilise des systèmes de tri avancés et offre des formations pour s'assurer que chaque type de plastique est correctement identifié et préparé pour le recyclage.</p>

        <h2 class="section-title">⚙️ Matériel de compaction</h2>
        <p>Pour réduire les coûts de transport et maximiser l'espace de stockage, nous proposons des solutions de compaction telles que des compacteurs de 20 à 30 m³, qui facilitent le transport et le traitement des plastiques collectés.</p>

        <h2 class="section-title">♻️ Traitement final, recyclage et réemploi du plastique</h2>
        <p>Les plastiques collectés sont traités dans des installations spécialisées où ils sont transformés en nouveaux produits, tels que des fibres textiles ou des conteneurs recyclés, contribuant ainsi à un cycle de vie circulaire des matériaux.</p>

        <h2 class="section-title">🌱 Action zéro déchet</h2>
        <p>Engagée dans la démarche zéro déchet, DMT Recyclage s'efforce de réduire la production de déchets à la source, de recycler efficacement les matériaux et de sensibiliser nos partenaires à des pratiques durables. Notre objectif est de transformer chaque déchet plastique en une nouvelle ressource, réduisant ainsi l'impact écologique des entreprises.</p>

        <h2 class="section-title">FAQ</h2>
        <p><strong>Q: Pourquoi choisir DMT Recyclage pour la gestion des plastiques ?</strong></p>
        <p>A: DMT offre une solution complète qui inclut la collecte, le tri, et le recyclage des plastiques, tout en réduisant la production de déchets à la source.</p>

        <p><strong>Q: Quels sont les avantages de la compaction des plastiques ?</strong></p>
        <p>A: La compaction réduit le volume des déchets, ce qui diminue les coûts de transport et optimise l'efficacité du recyclage.</p>

        <p>En choisissant DMT Recyclage, vous optez pour une gestion des déchets plastiques qui est à la fois respectueuse de l'environnement et conforme aux réglementations.</p>

        <p>Contactez-nous dès aujourd'hui pour découvrir comment nos services peuvent améliorer votre gestion des plastiques et renforcer votre engagement environnemental.</p>
    </section>
    <div class="row">
        <div class="col-lg-3">
            <a routerLink="/demander-une-collecte"
               fragment="contant-form"
               class="cmn-banner-btn d-block mt-3 btn-success btn-sm"><i
                class="bx bx-note"></i>Demander un collecte</a>
        </div>
    </div>
</div>

