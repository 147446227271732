import { Component } from '@angular/core';

@Component({
  selector: 'app-faire-une-collecte',
  templateUrl: './faire-une-collecte.component.html',
  styleUrls: ['./faire-une-collecte.component.scss']
})
export class FaireUneCollecteComponent {

}
