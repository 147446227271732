<app-preloader></app-preloader>
<app-navbar></app-navbar>
<router-outlet></router-outlet>
<footer class="footer-style-area pt-100 ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo.png" alt="Logo">
                        </a>
                        <ul class="footer-social">
                            <li>
                                <a href="#"
                                   target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#"
                                   data-url="#"
                                   target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/dmt-recyclage/"
                                   target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>VOUS ÊTES</h3>

                    <ul class="quick-link">
                        <li>
                            <a routerLink="/" [queryParams]="{scrollTo: 'you_are_1',}" queryParamsHandling="merge">Professionnel</a>
                        </li>
                        <li>
                            <a routerLink="/" [queryParams]="{scrollTo: 'you_are_2', }" queryParamsHandling="merge">Collectivités ou Syndics</a>
                        </li>
                        <li>
                            <a routerLink="/" [queryParams]="{scrollTo: 'you_are_3',}" queryParamsHandling="merge">Particulier</a>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Nos services</h3>
                    <ul class="quick-link">
                        <li class="list-group-item">
                            <a
                                routerLink="/services"
                                class="dropdown-item pointer"
                                fragment="collecte_et_recyclage"
                                queryParamsHandling="merge">Collecte & Recyclage</a>
                        </li>
                        <li class="list-group-item">
                            <a
                                routerLink="/services"
                                class="dropdown-item pointer"
                                fragment="bio_dechets"
                                queryParamsHandling="merge">Les bio déchets</a>
                        </li>
                        <li class="list-group-item">
                            <a
                                routerLink="/services"
                                class="dropdown-item pointer"
                                fragment="debarras"
                                queryParamsHandling="merge">Débarras et Déblaiement</a>
                        </li>
                        <li class="list-group-item">
                            <a
                                routerLink="/services"
                                class="dropdown-item pointer"
                                fragment="demantelement"
                                queryParamsHandling="merge">Démantèlement industriel</a>
                        </li>
                    </ul>
                </div>
            </div>



            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Support</h3>

                    <ul class="quick-link">
                        <li>
                            <a routerLink="/faq">FAQ</a>
                        </li>
                        <li>
                            <a routerLink="/contact" fragment="contant-form">Contact & Devis</a>
                        </li>
                        <li>
                            <a routerLink="/actuality">Actualités</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>Adresse</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:0982381798">0 982 381 798</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:contact@dmt-recyclage.fr">contact@dmt-recyclage.fr</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location'></i>
                            15 Rue de la Guivernone, 95310 Saint-Ouen-l'Aumône
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
