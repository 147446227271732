<!-- HEADER -->
<div class="activity-home-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choice-home-content">
                    <h1>Nos services</h1>
                    <p class="text-white">
                        DMT Recyclage est une entreprise spécialisée dans la
                        collecte et le recyclage des déchets.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<!--&lt;!&ndash; La gestion globale de vos déchets ( à la place des 5 flux) &ndash;&gt;-->
<!--<section class="three-process-area pb-70" id="collecte_et_recyclage">-->
<!--    <div class="counter-shape"></div>-->
<!--    <div class="container-fluid">-->
<!--        <div class="one-section-title two-section-title">-->
<!--            <h2>Gestion global de vos déchets</h2>-->
<!--            <span class="lead text-dart">-->
<!--                Gestion des Déchets avec DMT STP : Votre Partenaire Zéro Déchet-->
<!--            </span>-->
<!--            <p>-->
<!--                Chez DMT STP, la qualité et la satisfaction client sont notre-->
<!--                priorité. Faites confiance à notre expertise pour une gestion-->
<!--                des déchets moderne, agile et responsable.-->
<!--            </p>-->
<!--        </div>-->
<!--        <div class="row align-items-center">-->

<!--            <div class="col-sm-6 col-lg-4">-->
<!--                <div class="process-item">-->
<!--                    <div class="process-span"><span>01</span></div>-->
<!--                    <h3>Audit et Analyse Approfondie</h3>-->
<!--                    <p>-->
<!--                        Nous examinons votre gestion des déchets actuelle pour évaluer la répartition des flux, l'organisation, le respect des normes légales et la traçabilité.-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="process-item">-->
<!--                    <div class="process-span"><span>02</span></div>-->
<!--                    <h3>Objectif Zéro Déchet</h3>-->
<!--                    <p>-->
<!--                        Nous proposons et visons ensemble l'objectif ambitieux du zéro déchet, intégrant une démarche proactive pour réduire et valoriser au maximum vos déchets.-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="process-item">-->
<!--                    <div class="process-span"><span>03</span></div>-->
<!--                    <h3>Mise en Place d'une Gestion Globale</h3>-->
<!--                    <p>-->
<!--                        Nous établissons une gestion complète des déchets, incluant la segmentation des flux, un affichage clair dans les locaux, des pesées systématiques et une collecte efficace.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-sm-6 col-lg-4 text-center">-->
<!--                <div class="process-img">-->
<!--                    <img src="assets/img/dmt-100.jpg" alt="Process" />-->
<!--                    <img-->
<!--                        src="assets/img/home-three/process2.png"-->
<!--                        alt="Process" />-->
<!--                    <img-->
<!--                        src="assets/img/home-three/process3.png"-->
<!--                        alt="Process" />-->
<!--                    <img-->
<!--                        src="assets/img/home-three/process4.png"-->
<!--                        alt="Process" />-->
<!--                    <img-->
<!--                        src="assets/img/home-three/process4.png"-->
<!--                        alt="Process" />-->
<!--                    <img-->
<!--                        src="assets/img/home-three/process4.png"-->
<!--                        alt="Process" />-->
<!--                    <img-->
<!--                        src="assets/img/home-three/process4.png"-->
<!--                        alt="Process" />-->
<!--                </div>-->
<!--                <div class="text-center">-->
<!--                    <span class="text-dark">-->
<!--                        Chez DMT STP, la qualité et la satisfaction client sont-->
<!--                        notre priorité. Faites confiance à notre expertise pour-->
<!--                        une gestion des déchets moderne, agile et responsable.-->
<!--                    </span>-->
<!--                    <a routerLink="/contact"-->
<!--                        fragment="contant-form"-->
<!--                        class="cmn-banner-btn d-block mt-3"><i-->
<!--                            class="bx bx-note"></i>Demander un devis</a>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">-->
<!--                <div class="process-item process-two">-->
<!--                    <div class="process-span"><span>04</span></div>-->
<!--                    <h3>Collecte de Tous les Flux de Déchets</h3>-->
<!--                    <p>-->
<!--                        Nous prenons en charge tous les types de déchets provenant des bâtiments du tertiaire : papier, carton, plastique PET, canettes, bio-déchets, verre, et même les archives confidentielles.-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="process-item process-two">-->
<!--                    <div class="process-span"><span>05</span></div>-->
<!--                    <h3>Suivi et Pilotage Précis</h3>-->
<!--                    <p>-->
<!--                        Nous assurons un suivi rigoureux avec une facturation détaillée, un espace client pour un suivi en temps réel des prestations, et un tableau de bord offrant une vue d'ensemble de vos activités.-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="process-item process-two">-->
<!--                    <div class="process-span"><span>06</span></div>-->
<!--                    <h3>Formation et Sensibilisation</h3>-->
<!--                    <p>-->
<!--                        Nous proposons des outils comme une application guide de tri et organisons des journées de sensibilisation pour former et sensibiliser votre personnel à adopter des pratiques plus durables.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->

<!--        </div>-->
<!--    </div>-->

<!--</section>-->

<!-- les bio déchets -->
<div id="bio_dechets" class="core-service-area one-faq-area pt-5">
    <div class="container">
        <div class="main-section-title">
            <h2 class="text-uppercase">Les Biodéchets</h2>
            <p>
                Les biodéchets sont des déchets organiques d'origine végétale ou
                animale pouvant être décomposés par des organismes vivants en
                conditions aérobies ou anaérobies. En 2024, la loi impose aux
                entreprises la collecte séparée et la valorisation des
                biodéchets, dans le cadre de la transition vers une économie
                circulaire et durable.
            </p>
        </div>
        <div class="faq-content">
            <ul class="accordion">
                <li>
                    <a>Collecte des Biodéchets</a>
                    <p>
                        Nous proposons des solutions de collecte des biodéchets
                        adaptées à chaque type d'entreprise. Des bacs de 240
                        litres peuvent être utilisés pour les RIE (Restaurants
                        d'Entreprise) jusqu'aux petites entités, permettant une
                        collecte spécifique directement dans des bioréacteurs
                        jusqu'aux étages des immeubles.

                    </p>
                </li>
                <li>
                    <a>Filières de Valorisation</a>
                    <p>
                        Nous offrons deux types de filières pour la valorisation
                        des biodéchets :

                    </p>
                </li>
                <li>
                    <a>Méthanisation</a>
                    <p>
                        La méthanisation est un processus biologique de
                        dégradation des matières organiques en l'absence
                        d'oxygène, produisant du biogaz utilisable comme source
                        d'énergie renouvelable. Cette filière permet de
                        valoriser les biodéchets en produisant du biogaz et un
                        digestat organique utilisable comme fertilisant.

                    </p>
                </li>
                <li>
                    <a>Compostage</a>
                    <p>
                        Le compostage est un processus de décomposition des
                        matières organiques en présence d'oxygène, produisant un
                        compost riche en nutriments. Cette filière permet de
                        valoriser les biodéchets en produisant un amendement
                        organique utilisable pour améliorer la qualité des
                        sols.Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua Ut enim ad minim veniam
                    </p>
                </li>
                <li>
                    <a>Avantages Environnementaux</a>
                    <p>
                        La gestion des biodéchets offre de nombreux avantages
                        environnementaux, notamment la réduction des émissions
                        de gaz à effet de serre, la préservation des ressources
                        naturelles et la production d'énergie renouvelable.
                    </p>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="faq-img p-5">
            <img src="/assets/img/biodechet-portait.jpeg" alt="FAQ" class="img img-fluid"/>
        </div>
    </div>
    <div class="container">
        <a routerLink="/contact" fragment="contant-form"
            class="cmn-banner-btn  mt-3"><i
                class="bx bx-note"></i>Demander un devis</a>
    </div>
    <span id="debarras"></span>
</div>

<!-- les débarras -->
<section
    id="services"
    class="one-service-area two-service-area six-nearly-area pt-5">
    <div class="container">
        <div  class="one-section-title">
            <h2>Les Débarras (archives & Mobiliers )</h2>
            <p>
                Pour une tranquillité d'esprit totale, confiez vos archives à
                des experts. DMT Recyclage offre des prestations exhaustives
                pour répondre à vos besoins spécifiques.
            </p>
        </div>

        <div class="one-faq-area four-faq-area pb-70">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="faq-content">
                            <ul class="accordion">
                                <li>
                                    <a>Prestations Exhaustives de DMT
                                        Recyclage</a>
                                    <p>
                                        <b class="text-primary">- Contenants sur
                                            Mesure :</b>
                                        Nous proposons des chariots métalliques
                                        à roulettes et des bennes de 15m³,
                                        adaptés à vos besoins. <br />
                                        <b class="text-primary">- Manutention et
                                            Transport :</b>
                                        De la livraison des contenants à la
                                        collecte, tri et conditionnement dans un
                                        centre agréé, nous nous occupons de
                                        tout.<br />
                                        <b class="text-primary">- Destruction
                                            Certifiée :</b>
                                        Vos documents sont soumis à un broyage
                                        sécurisé, avec un certificat de
                                        destruction garantissant l'élimination
                                        définitive.<br />
                                    </p>
                                </li>
                                <li>
                                    <a>Opérations sur-Mesure</a>
                                    <p>
                                        <b class="text-primary">- Adaptabilité
                                            Totale :</b>
                                        Besoins particuliers ? Nous concevons
                                        des opérations sur mesure pour répondre
                                        spécifiquement à vos exigences. <br />
                                        <b class="text-primary">- Conseil
                                            Personnalisé :</b>
                                        Notre équipe réalise un diagnostic
                                        approfondi pour proposer une solution
                                        parfaitement adaptée à vos
                                        besoins.<br />
                                    </p>
                                </li>
                                <li>
                                    <a>Preuve de Destruction pour la
                                        Conformité</a>
                                    <p>
                                        <b class="text-primary">- Certificats
                                            Disponibles en Ligne
                                            :</b>
                                        Tous nos certificats de destruction sont
                                        accessibles via votre espace client,
                                        assurant une preuve facile en cas de
                                        besoin. <br />
                                        <b class="text-primary">- Conformité
                                            RGPD :</b>
                                        Protégez-vous contre tout litige en
                                        respectant strictement les normes RGPD
                                        avec notre procédure sécurisée de bout
                                        en bout.<br />
                                    </p>
                                </li>
                                <li>
                                    <a>La confiance</a>
                                    <p>
                                        Faites confiance à DMT Recyclage pour
                                        une gestion complète, sûre et conforme
                                        de vos archives. Libérez-vous des tracas
                                        administratifs et concentrez-vous sur
                                        votre activité principale.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6 pr-0 mr-3">
                        <div class="faq-img-two">
                            <video #myVideo playsinline  controls loop class="mr-5" muted style="width: 80%">
                                <source src="assets/img/debarras.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Le materiel proposé -->
<div id="materiel_propose" class="core-service-area ">
    <div class="container">
        <div class="main-section-title">
            <h2 class="text-uppercase">Le matériel proposé</h2>
        </div>
    </div>
</div>

<!-- le démantèlement  -->
<section id="services" class="one-service-area two-service-area pt-3">
    <div class="container">
        <div id="debarras" class="one-section-title">
            <h2>Service de Débarras de Meubles Complet</h2>
            <p>
                Lorsque le débarras de meubles devient une tâche complexe, DMT
                Recyclage se présente comme la solution idéale pour simplifier
                la vie des particuliers et des entreprises.
            </p>
        </div>
        <div class="process-style-area-without-bg pt-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="process-style-accordion">
                            <div class="accordion" id="ProcessAccordion">
                                <div class="accordion-item">
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne">
                                        <span>01</span> Débarras de Meubles
                                        Simple et Efficace
                                    </button>
                                    <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse show"
                                        data-bs-parent="#ProcessAccordion">
                                        <div class="accordion-body">
                                            <ul class="list">
                                                <li>
                                                    <b class="text-primary"><i
                                                            class="bx bx-check"></i>
                                                        Contactez les Experts
                                                        pour un Devis Gratuit
                                                        :</b>
                                                    Évitez les soucis liés au
                                                    manque d'équipement ou à la
                                                    difficulté de déplacer des
                                                    meubles lourds.
                                                </li>
                                                <li>
                                                    <b class="text-primary"><i
                                                            class="bx bx-check"></i>
                                                        Intervention :</b>
                                                    Nos experts assurent une
                                                    évacuation rapide et
                                                    efficace des meubles
                                                    inutiles ou endommagés.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        <span>02</span> Gestion Responsable des
                                        Meubles pour les Entreprises
                                    </button>
                                    <div
                                        id="collapseTwo"
                                        class="accordion-collapse collapse"
                                        data-bs-parent="#ProcessAccordion">
                                        <div class="accordion-body">
                                            <ul class="list">
                                                <li>
                                                    <b class="text-primary"><i
                                                            class="bx bx-check"></i>Réemploi
                                                        et Recyclage
                                                        :</b>
                                                    Évaluation des meubles pour
                                                    une possible réutilisation,
                                                    favorisant une seconde vie à
                                                    des objets réutilisables.
                                                    Ceux non valorisés sont
                                                    dirigés vers associations
                                                    caritatives, centres de tri,
                                                    ou la déchetterie.
                                                </li>
                                                <li>
                                                    <b class="text-primary"><i
                                                            class="bx bx-check"></i>Location
                                                        de Benne
                                                        Simplifiée :</b>
                                                    Pour des débarras
                                                    importants, la location
                                                    d'une benne assure un
                                                    débarras rapide et efficace.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree">
                                        <span>03</span> Tarification
                                        Transparente et Avantageuse
                                    </button>
                                    <div
                                        id="collapseThree"
                                        class="accordion-collapse collapse"
                                        data-bs-parent="#ProcessAccordion">
                                        <div class="accordion-body">
                                            <ul class="list">
                                                <li>
                                                    <b class="text-primary"><i
                                                            class="bx bx-check"></i>
                                                        Services Additionnels
                                                        pour Entreprises:
                                                    </b>
                                                    Manutentionnaires et camions
                                                    pour la collecte et le
                                                    retrait des mobiliers, même
                                                    à plusieurs étages.
                                                </li>
                                                <li>
                                                    <b class="text-primary"><i
                                                            class="bx bx-check"></i>
                                                        Facturation au Mètre
                                                        Cube :</b>
                                                    La tarification dépend du
                                                    volume des meubles. Si la
                                                    valeur des meubles à
                                                    récupérer compense la
                                                    prestation
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <br />
                        <br />
                        <div class="process-style-wrap-image">
                            <img src="/assets/img/img_14.png" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span id="demantelement"></span>
</section>

<div class="buying-home-area ptb-100 six-nearly-area">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-5 col-md-12">
                <div class="buying-home-image">
                    <img src="/assets/img/service/dementelements.png" alt="image" />

                    <div class="counter-box">
                        <h4></h4>
                    </div>
                </div>
            </div>

            <div  class="col-lg-7 col-md-12">
                <div class="buying-home-content">
                    <h3>Démantèlement industriel</h3>
                    <p>
                        DMT Recyclage vous propose des solutions de
                        démantèlement industriel adaptées à vos besoins et à vos
                        contraintes. Nous intervenons sur site pour démanteler
                        vos installations industrielles et vous accompagnons
                        dans la gestion de vos déchets.
                    </p>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class="bx bx-check"></i>
                                </div>
                                <h4>Enlèvement des cloisons dans les bureaux</h4>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class="bx bx-check"></i>
                                </div>
                                <h4>
                                    Retrait des câblages et des plateaux informatiques.
                                </h4>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class="bx bx-check"></i>
                                </div>
                                <h4>
                                    Démantèlement des armoires d'archives mobiles.
                                </h4>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class="bx bx-check"></i>
                                </div>
                                <h4>
                                    Retrait des installations de climatisation industrielles.
                                </h4>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class="bx bx-check"></i>
                                </div>
                                <h4>
                                    Petites démolitions de cloisonnement.
                                </h4>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class="bx bx-check"></i>
                                </div>
                                <h4>Démantèlement des meubles informatiques.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section id="services" class="one-service-area two-service-area ptb-100">
    <div class="container">

        <div class="tab-content" id="nav-tabContent">
            <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab">
                <div class="service-advisor">
                    <div class="row align-items-start">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="/assets/img/activities_trash.jpg"
                                    alt="Advisor" />
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>
                                    La Collecte des Déchets de Bureau par les 5
                                    Flux
                                </h2>
                                <p>
                                    Dans un monde axé sur la durabilité, notre
                                    approche repose sur la séparation des
                                    déchets dès leur source. En ciblant les 5
                                    flux essentiels (DIB, Papier, Carton,
                                    Plastique, Verre), nous optimisons le
                                    recyclage et réduisons l'empreinte carbone,
                                    offrant à nos clients une solution
                                    respectueuse de l'environnement.
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        DIB (Déchets Industriels Banals) </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Papier
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Carton
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Plastique
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Verre
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor2.jpg"
                                    alt="Advisor" />
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Apply For Loan</h2>
                                <p>
                                    Our plan dolor sit amet conseetur diisci
                                    velit sed quiLorem ipsum dolor sit ame
                                    consectetur adipisicing elit
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Respect for all people
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Excellence in everything we do
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Truthfulness in our business
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Unquestionable integrity
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="tab-pane fade"
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor3.jpg"
                                    alt="Advisor" />
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Refinancing Your Home</h2>
                                <p>
                                    Our plan dolor sit amet conseetur diisci
                                    velit sed quiLorem ipsum dolor sit ame
                                    consectetur adipisicing elit
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Respect for all people
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Excellence in everything we do
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Truthfulness in our business
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Unquestionable integrity
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="tab-pane fade"
                id="nav-x"
                role="tabpanel"
                aria-labelledby="nav-x-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor4.jpg"
                                    alt="Advisor" />
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Capital Management</h2>
                                <p>
                                    Our plan dolor sit amet conseetur diisci
                                    velit sed quiLorem ipsum dolor sit ame
                                    consectetur adipisicing elit
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Respect for all people
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Excellence in everything we do
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Truthfulness in our business
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Unquestionable integrity
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="tab-pane fade"
                id="nav-y"
                role="tabpanel"
                aria-labelledby="nav-y-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor5.jpg"
                                    alt="Advisor" />
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Renovation House</h2>
                                <p>
                                    Our plan dolor sit amet conseetur diisci
                                    velit sed quiLorem ipsum dolor sit ame
                                    consectetur adipisicing elit
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Respect for all people
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Excellence in everything we do
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Truthfulness in our business
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Unquestionable integrity
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="tab-pane fade"
                id="nav-z"
                role="tabpanel"
                aria-labelledby="nav-z-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img
                                    src="assets/img/home-one/advisor6.jpg"
                                    alt="Advisor" />
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Refinance Advisor</h2>
                                <p>
                                    Our plan dolor sit amet conseetur diisci
                                    velit sed quiLorem ipsum dolor sit ame
                                    consectetur adipisicing elit
                                </p>
                                <ul>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Respect for all people
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Excellence in everything we do
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Truthfulness in our business
                                    </li>
                                    <li>
                                        <i class="bx bxs-chevrons-right"></i>
                                        Unquestionable integrity
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="subscribe-style-area bg-style-two ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="subscribe-style-wrap-content">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <h2>
                            Voulez-vous recevoir des mises à jour sur les
                            prochaines nouveautés ?
                        </h2>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <form class="newsletter-form" data-toggle="validator">
                            <input
                                type="email"
                                class="form-control"
                                placeholder="Tapez votre email"
                                name="EMAIL"
                                required
                                autocomplete="off" />

                            <button class="main-default-btn" type="submit">
                                S'abonner
                            </button>
                            <div
                                id="validator-newsletter"
                                class="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
