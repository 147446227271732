
<div class="find-loan-officer-area ptb-100 mt-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="find-loan-officer-form">
                    <h3>Finaliser la demande de collecte</h3>
                    
                    <form>
                        <div class="form-group">
                            <label>Adresse de la Prestation :</label>
                            <input type="text" class="form-control" placeholder="Adresse exacte">
                        </div>
                        <div class="form-group">
                            <label>Date de Collecte Souhaitée :</label>
                            <input type="date" class="form-control">
                        </div>


                        <div class="form-group">
                            <label>Prénom Nom</label>
                            <input type="text" class="form-control">
                        </div>


                        <div class="form-group">
                            <label>Email</label>
                            <input type="text" class="form-control">
                        </div>


                        <a type="submit" class="main-default-btn" href="">Obtenez Votre Devis Gratuit</a>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="find-loan-officer-image">
                    <div class="find-loan-officer-form">
                        <h3 class="mb-2">Detail du service</h3>
                        <ul>
                            <li>Contenant : Bene</li>
                            <li>Volume : 8m3</li>
                            <li>Matiere : Papier Carton</li>
                        </ul>
                            <h3>Forfait : 456,00 €</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<section id="services" class="one-service-area pt-100">
    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-7">

            </div>
            <div class="col-lg-5">
                <div class="main-banner-form-wrap pt-5 mt-5" style="background-color: #BBBBBB ">
                    <h3 class="pt-5">Faites Votre Devis en Ligne en 3 Clics !</h3>

                <form>
                    <div class="form-group">
                        <label>Saisir une adresse</label>
                        <input type="text" class="form-control">
                    </div>

                    <div class="form-group">
                        <label>Prénom Nom</label>
                        <input type="text" class="form-control">
                    </div>


                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" class="form-control">
                    </div>


                    <button type="submit" class="main-default-btn">Obtenez Votre Devis Gratuit</button>
                </form>
                </div>
            </div>
        </div>
        <h2 class="mb-4">Liste des Prix</h2>
        <table class="table table-bordered">
            <thead class="thead-dark">
            <tr>
                <th scope="col">Prix</th>
                <th scope="col">Prix</th>
                <th scope="col">Prix</th>
                <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>456,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>516,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>840,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>1 080,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>40,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>360,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>396,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>275,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>300,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>325,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>350,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>355,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>275,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>300,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>325,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            <tr>
                <td>350,00 €</td>
                <td><button class="btn btn-info text-white">Obtenir un devis</button></td>
            </tr>
            </tbody>
        </table>
    </div>
</section>

