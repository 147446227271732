<header class="main-header-area">
    <!-- <div class="main-top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-7">
                    <ul class="top-header-information">
                        <li>
                            <span>Téléphone: </span> <a
                            href="tel:4234543123678">+0 982 381 798</a>
                        </li>
                        <li>
                            <span>Email: </span> <a
                            href="mailto:info@levi.com">info@levi.com</a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-5 col-md-5">
                    <ul class="top-header-social">
                        <li>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?app_id=309437425817038&sdk=joey&u=http%3A%2F%2Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php"
                                target="_blank">
                                <i class='bx bxl-facebook'></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/share"
                               data-url="http://www.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php"
                               target="_blank">
                                <i class='bx bxl-twitter'></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://plus.google.com/share?url=http%3A%2F%2Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php%2F%23.VDP-D0ol2xo.google_plusone_share&t=Neue+mode+,+grossiste+tissus+et+mercerie+France+Belgique."
                                target="_blank">
                                <i class='bx bxl-google-plus'></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/uas/connect/user-signin?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Fcws%2Fshare%3FisFramed%3Dfalse%26lang%3Den_US%26url%3Dhttp%253A%252F%252Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php%26original_referer%3Dhttp%253A%252F%252F127%252E0%252E0%252E1%252Fneue-mode%252Ebe%252Fv1%252Faccueil-grossiste-tissus-france-belgique%252Ephp%26token%3D%26_ts%3D1412693201204%252E9148"
                                target="_blank">
                                <i class='bx bxl-linkedin'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->

    <div class="main-navbar">
        <div class="container-fluid">
            <nav
                class="navbar navbar-style-three navbar-expand-lg navbar-light">
                <div class="logo">
                    <a routerLink="/" class="pointer">
                        <img
                            src="assets/img/logo.png"
                            class="black-logo"
                            alt="Logo" />
                    </a>
                </div>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                data-bs-toggle="dropdown">DMT recyclage</a>

                            <ul class="dropdown-menu">
                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/"
                                        class="dropdown-item pointer"
                                        fragment="about"
                                        queryParamsHandling="merge">
                                        Qui sommes nous ?
                                    </a>
                                </li>
                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/"
                                        class="dropdown-item pointer"
                                        fragment="values"
                                        queryParamsHandling="merge">Nos
                                        valeurs</a>
                                </li>
                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/"
                                        class="dropdown-item pointer"
                                        fragment="mission"
                                        queryParamsHandling="merge">
                                        Notre mission</a>
                                </li>


                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/"
                                        class="dropdown-item pointer"
                                        fragment="references"
                                        queryParamsHandling="merge">Nos
                                        références</a>
                                </li>

                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/"
                                        class="dropdown-item pointer"
                                        fragment="engagements"
                                        queryParamsHandling="merge">Objectif
                                        zéro déchet</a>
                                </li>
                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/"
                                        class="dropdown-item pointer"
                                        fragment="temoignages"
                                        queryParamsHandling="merge">
                                        Témoignages</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown servicelist">
                            <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                data-bs-toggle="dropdown"
                                (mouseover)="scrollToServices()" style="color: white !important;">Nos services</a>

                            <ul class="dropdown-menu">
                                <li class="list-group-item">
                                    <a
                                        routerLink="/services"
                                        class="dropdown-item pointer"
                                        fragment="bio_dechets"
                                        queryParamsHandling="merge">
                                        Les bio déchets</a>
                                </li>
                                <li class="list-group-item">
                                    <a
                                        routerLink="/services"
                                        class="dropdown-item pointer"
                                        fragment="debarras"
                                        queryParamsHandling="merge">
                                        Débarras</a>
                                </li>
                                <li class="list-group-item">
                                    <a
                                        routerLink="/services"
                                        class="dropdown-item pointer"
                                        fragment="demantelement"
                                        queryParamsHandling="merge">
                                        Démantèlement industriel</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a routerLink="/materiel-propose" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Le matériel proposé
                            </a>

                            <ul class="dropdown-menu">
                                <li class="list-group-item ps-3">
                                    <a routerLink="/materiel-propose" class="dropdown-item pointer" fragment="nav-home" queryParamsHandling="merge">
                                        Les bacs plastiques
                                    </a>
                                </li>
                                <li class="list-group-item ps-3">
                                    <a routerLink="/materiel-propose" class="dropdown-item pointer" fragment="nav-profile" queryParamsHandling="merge">
                                        Les caisses palettes
                                    </a>
                                </li>
                                <li class="list-group-item ps-3">
                                    <a routerLink="/materiel-propose" class="dropdown-item pointer" fragment="nav-contact" queryParamsHandling="merge">
                                        Les rolls
                                    </a>
                                </li>
                                <li class="list-group-item ps-3">
                                    <a routerLink="/materiel-propose" class="dropdown-item pointer" fragment="nav-x" queryParamsHandling="merge">Les bennes</a>
                                </li>
                                <li class="list-group-item ps-3">
                                    <a routerLink="/materiel-propose" class="dropdown-item pointer" fragment="nav-y" queryParamsHandling="merge">Les points d’apport volontaires</a>
                                </li>
                                <li class="list-group-item ps-3">
                                    <a routerLink="/materiel-propose" class="dropdown-item pointer" fragment="nav-z" queryParamsHandling="merge">Les bioseaux</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                routerLink="/réglementations"
                                data-bs-toggle="dropdown">Réglementation</a>

                            <ul class="dropdown-menu">
                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/reglementations"
                                        class="dropdown-item pointer"
                                        fragment="chronologie"
                                        queryParamsHandling="merge">
                                        Chronologie</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a
                                routerLink="/matieres"
                                class="nav-link dropdown-toggle"
                                data-bs-toggle="dropdown">
                               Matières
                            </a>
                            <ul class="dropdown-menu">
                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/matieres"
                                        class="dropdown-item pointer"
                                        fragment="papiers"
                                        queryParamsHandling="merge">
                                        Papiers</a>
                                </li>

                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/carton"
                                        class="dropdown-item pointer"
                                        fragment="carton"
                                        queryParamsHandling="merge">Carton</a>
                                </li>

                                <li class="list-group-item ps-3">
                                    <a
                                        routerLink="/plastique"
                                        class="dropdown-item pointer"
                                        fragment="carton"
                                        queryParamsHandling="merge">Plastique</a>
                                </li>

                            </ul>
                        </li>




                        <li class="nav-item">
                            <a routerLink="/actuality" class="nav-link">
                                Actualités
                            </a>
                        </li>
                    </ul>

                    <div class="others-option">
                        <div class="d-flex align-items-center">
                            <div class="option-item">

                                <a routerLink="/contact" fragment="contant-form" class="sign-up" style="color: white !important;">
                                    Contact/Devis
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>
