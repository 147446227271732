<div class="activity-home-area-2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choice-home-content">
                    <h1>Carton</h1>
                    <p class="text-white">
                        DMT Recyclage est votre partenaire pour la gestion, le tri, et le recyclage des déchets carton en entreprise.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container my-5">

    <section class="row g-0">
        <div class="col-md-6 bg-position-center bg-size-cover bg-secondary" style="min-height: 15rem; background-image: url(assets/img/carton-boxes.jpg); background-size: cover; background-repeat: no-repeat"></div>
        <div class="col-md-6 px-3 px-md-5 py-5">
            <div class="mx-auto py-lg-5" style="max-width: 35rem;">
                <h2 class="h3 pb-3 section-title" style="color: black;"><strong>📦 Qu'est-ce que le carton ?</strong></h2>
                <p class="fs-sm pb-3 text-muted">
                    Le carton est un matériau essentiel dans la gestion des déchets de bureau, largement utilisé pour l'emballage et le stockage. C'est une matière à base de fibres de cellulose, souvent ondulée ou plate, utilisée dans la fabrication de boîtes, d'emballages et de matériaux de protection. Le carton est hautement valorisable en raison de sa capacité à être recyclé plusieurs fois, réduisant ainsi l'impact environnemental des déchets.
                </p>
                <h2 class="h3 pb-3 section-title" style="color: black;"><strong>🏢 Pourquoi le carton est-il utilisé dans les bureaux ?</strong></h2>
                <p>
                    Dans le secteur tertiaire, le carton est privilégié pour sa robustesse, sa légèreté, et sa capacité à protéger le contenu durant le transport et le stockage. Il est couramment utilisé pour les boîtes d'archivage, l'emballage des fournitures de bureau et le conditionnement des produits livrés. Cependant, en raison de son volume, il nécessite une gestion efficace pour éviter qu'il ne prenne trop de place inutilement dans les locaux.
                </p>
                <h2 class="h3 pb-3 section-title" style="color: black;"><strong>📊 Quantité et sources de carton dans l'entreprise</strong></h2>
                <p>
                    Le carton constitue une part importante des déchets de bureau. Les livraisons régulières de fournitures, les colis entrants, et les opérations quotidiennes d'archivage génèrent une quantité significative de déchets carton. Il est essentiel pour les entreprises de mettre en place des systèmes de gestion adaptés pour gérer efficacement ce flux de déchets.
                </p>
            </div>
        </div>
    </section>
    <!-- Row: Delivery-->
    <section class="row g-0">
        <div class="col-md-6 bg-position-center bg-size-cover bg-secondary order-md-2" style="min-height: 15rem; background-image: url(assets/img/carton2.jpg); background-size: cover; background-repeat: no-repeat"></div>
        <div class="col-md-6 px-3 px-md-5 py-5 order-md-1">
            <div class="mx-auto py-lg-5" style="max-width: 35rem;">
                <h2 class="h3 pb-3 section-title" style="color: black;">
                    🗑️ Moyens de stockage et de collecte du carton</h2>
                <p class="fs-sm pb-3 text-muted">
                    DMT Recyclage recommande l'utilisation de bacs et de rolls pour le stockage temporaire du carton dans les bureaux. Ces contenants permettent de maintenir un espace de travail organisé tout en facilitant le tri à la source. Pour les entreprises générant de grandes quantités de carton, l'installation de compacteurs ou de presses à balles est conseillée. Ces équipements réduisent le volume des déchets, optimisent l'espace de stockage, et diminuent les coûts de transport.
                </p>
                <h2 class="h3 pb-3 section-title" style="color: black;">🚛 Procédure de collecte</h2>
                <p class="fs-sm pb-3 text-muted">
                    Notre service de collecte s'adapte à vos besoins spécifiques. Nous programmons des collectes régulières et offrons la flexibilité de récupérer les cartons directement dans les bureaux ou dans les zones de stockage dédiées. Nos équipes formées assurent une gestion rapide et efficace, minimisant les perturbations sur votre lieu de travail.
                </p>

                <h2 class="h3 pb-3 section-title" style="color: black;">🔄 Systèmes de tri et préparation au recyclage</h2>
                <p class="fs-sm pb-3 text-muted">
                    Un tri efficace à la source est crucial pour maximiser la valorisation du carton. Nous proposons des guides de tri personnalisés et des formations pour aider vos employés à bien trier les déchets carton. Cette préparation garantit que le carton est prêt pour le recyclage, réduisant ainsi le risque de contamination par d'autres matières.
                </p>

            </div>
        </div>
    </section>

</div>


<div class="container my-5">

    <!-- Matériel de compaction -->
    <section id="compaction-carton">
        <h2 class="section-title">⚙️ Matériel de compaction</h2>
        <p>
            L'utilisation de compacteurs et de presses à balles est une solution optimale pour gérer les volumes élevés de carton. Ces équipements réduisent considérablement la taille des déchets, rendant le transport et le stockage plus efficaces. DMT Recyclage offre une gamme de compacteurs adaptés à vos besoins, allant des petits modèles pour les bureaux aux unités industrielles pour les grandes installations.
        </p>
    </section>

    <!-- Traitement final, recyclage et réemploi du carton -->
    <section id="recyclage-carton">
        <h2 class="section-title">♻️ Traitement final, recyclage et réemploi du carton</h2>
        <p>
            Après collecte, le carton est acheminé vers des centres de traitement spécialisés où il est recyclé en nouvelles fibres de papier. Ce processus de recyclage contribue à économiser les ressources naturelles et à réduire l'empreinte carbone de votre entreprise. DMT Recyclage propose également l'achat de carton sous condition de volume, offrant ainsi une opportunité supplémentaire de valorisation économique.
        </p>
    </section>

    <!-- Action zéro déchet -->
    <section id="action-zero-dechet-carton">
        <h2 class="section-title">🌱 Action zéro déchet</h2>
        <p>
            DMT Recyclage s'engage dans une démarche zéro déchet en mettant l'accent sur la réduction, le réemploi et le recyclage du carton. Nous aidons nos clients à minimiser les déchets à la source et à maximiser la valorisation des matériaux, contribuant ainsi à un avenir plus durable. En collaborant avec nous, vous faites le choix d'une gestion des déchets éco-responsable et innovante.
        </p>
    </section>

    <!-- FAQ -->
    <section id="faq-carton">
        <h2 class="section-title">FAQ</h2>
    </section>

    <p class="d-inline-flex gap-1">
        <a class="btn btn-primary" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Q: Quels types de carton pouvez-vous collecter ?</a>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">Q: Quels sont les avantages d'utiliser un compacteur de carton ?</button>
    </p>
    <div class="row">
        <div class="col">
            <div class="collapse multi-collapse" id="multiCollapseExample1">
                <div class="card card-body">
                    A: Nous collectons tous les types de carton, y compris les boîtes, les cartons d'emballage et les feuilles de carton plat, sous réserve qu'ils soient exempts de contaminants.
                </div>
            </div>
        </div>
        <div class="col">
            <div class="collapse multi-collapse" id="multiCollapseExample2">
                <div class="card card-body">
                    A: Les compacteurs réduisent le volume des déchets, optimisent l'espace de stockage, et diminuent les coûts de transport, tout en préparant le carton pour un recyclage efficace.
                </div>
            </div>
        </div>
    </div>
</div>
