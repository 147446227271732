import { Component, OnInit } from '@angular/core';
import { DevisService } from '../devis.service';

@Component({
    selector: 'app-demande-de-devis',
    templateUrl: './demande-de-devis.component.html',
    styleUrls: ['./demande-de-devis.component.scss']
})
export class DemandeDeDevisComponent implements OnInit {
    secteurs: { id: number, libelle: string }[] = [];
    categories: { id: number, libelle: string }[] = [];
    volumes: { id: number, libelle: string }[] = [];

    selectedSecteur: number | null = null;
    selectedCategory: number | null = null;

    constructor(private devisService: DevisService) { }

    ngOnInit(): void {
        this.devisService.getSecteurs().subscribe(response => {
            if (response.success) {
                this.secteurs = response.data;
            }
        });
    }

    onSecteurChange(secteurId: number): void {
        this.selectedSecteur = secteurId;
        this.devisService.getCategories(secteurId).subscribe(response => {
            if (response.success) {
                this.categories = response.data;
                this.volumes = [];
            }
        });
    }

    onCategoryChange(categoryId: number): void {
        this.selectedCategory = categoryId;
        this.devisService.getVolumes(categoryId).subscribe(response => {
            if (response.success) {
                this.volumes = response.data;
            }
        });
    }
}
