import { Component } from '@angular/core';

@Component({
  selector: 'app-demander-une-collecte-chantier',
  templateUrl: './demander-une-collecte-chantier.component.html',
  styleUrls: ['./demander-une-collecte-chantier.component.scss']
})
export class DemanderUneCollecteChantierComponent {
    contenants = [
        {
            type: "Benne",
            volumes: ["10m³", "15m³", "20m³", "30m³"]
        },
        {
            type: "Bac",
            volumes: ["240L", "360L", "660L", "1000L"]
        },
        {
            type: "Roll",
            volumes: ["1m³", "2m³"]
        },
        {
            type: "Caisse Palette",
            volumes: ["600L", "1000L"]
        },
        {
            type: "Vrac",
            volumes: ["10 en M³"]
        }
    ];

    selectedType: string = '';
    volumes: string[] = [];

    constructor() {
        console.log('DemanderUneCollecteComponent initialized');
    }

    ngOnInit(): void {}

    onTypeChange(event: any): void {
        const selectedType = event.target.value;
        console.log('Selected Type:', selectedType);
        const contenant = this.contenants.find(c => c.type === selectedType);
        this.volumes = contenant ? contenant.volumes : [];
        console.log('Updated Volumes:', this.volumes);
    }
}
