import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DevisService {
    private apiUrl = 'http://dmtapi.test/api';

    constructor(private http: HttpClient) { }

    getSecteurs(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/secteurs`);
    }

    getCategories(secteurId: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/secteurs/${secteurId}/categories`);
    }

    getVolumes(categoryId: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/categories/${categoryId}/volumes`);
    }
}
