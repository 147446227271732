
<div class="three-banner-area four-banner-area" style="background-image: url('assets/img/dechets.jpg')">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content">
                            <span>
                                Votre Solution de Gestion des Déchets en Île-de-France
                            </span>
                            <h1>Collecter mes dechêts</h1>
                            <p>Optimisez votre gestion des déchets avec DMT Recyclage.</p>
                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" href="#about">En savoir plus <i class='bx bx-right-arrow-alt'></i></a>
                                </li>
                                <li>
                                    <a class="banner-play popup-youtube" href="https://www.youtube.com/watch?v=aqz-KE-bpKQ"><i class='bx bx-play'></i></a>
                                    <span></span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-5" style="padding-top: 105px !important;">
                        <div class="banner-form ">
                            <div class="one-section-title three-section-title">
                                <span class="sub-title"># Faites Votre Devis en Ligne en 3 Clics !</span>
                            </div>


                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                    <form>
                                        <div class="row">
                                            <div class="col-sm-12 col-lg-12">
                                                <label><strong>Type de Contenant</strong></label>
                                                <div class="form-group">
                                                    <label><i class='bx bx-money'></i></label>
                                                    <select class="form-control" (change)="onTypeChange($event)">
                                                        <option value="">Sélectionner un type</option>
                                                        <option *ngFor="let contenant of contenants" [value]="contenant.type">{{ contenant.type }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-12">
                                                <label><strong>Volume</strong></label>
                                                <div class="form-group">
                                                    <label><i class='bx bx-money'></i></label>
                                                    <select class="form-control">
                                                        <option value="">Sélectionner un volume</option>
                                                        <option>10m³</option>
                                                        <option>15m³</option>
                                                        <option>20m³</option>
                                                        <option>30m³</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-12">
                                                <label><strong>Matière à Collecter :</strong></label>
                                                <div class="form-group">
                                                    <label><i class='bx bx-dollar-circle'></i></label>
                                                    <select name="" id="" class="form-control">
                                                        <option>Papier et Carton</option>
                                                        <option>Plastique PET et Canettes</option>
                                                        <option>Bio-déchets</option>
                                                        <option>Verre</option>
                                                        <option>Déchets Industriels Banals (DIB)</option>
                                                        <option>Bois</option>
                                                        <option>Ferraille</option>
                                                        <option>Autres (préciser)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-12">
                                                <label><strong>Adresse de la Prestation :</strong></label>
                                                <div class="form-group">
                                                    <label><i class='bx bx-dollar-circle'></i></label>
                                                    <input type="text" class="form-control" >
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-12">
                                                <label><strong>Date de la prestation :</strong></label>
                                                <div class="form-group">
                                                    <label><i class='bx bx-dollar-circle'></i></label>
                                                    <input type="date" class="form-control" >
                                                </div>
                                            </div>
                                            <div class="col-lg-12 mt-2">
                                                <button type="submit" class="btn cmn-banner-btn">Obtenez Votre Devis Gratuit <i class='bx bx-right-arrow-alt'></i></button>
                                            </div>
                                        </div>
                                    </form>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="about" class="about-style-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-image">
                    <img src="assets/img/qsnous.webp" alt="image">

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-style-content">
                    <h3>Pourquoi Choisir DMT Recyclage ?</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-md-12">
                            <div class="about-style-content">
                                <p>
                                    <strong>Services Adaptés à Vos Besoins :</strong> <br>
                                    Que vous ayez besoin de bennes, bacs, rolls ou caisses palettes, nous avons la solution adaptée pour tous vos besoins de gestion des déchets.</p>
                            </div>
                            <div class="about-style-content">
                                <p><strong>Expertise dans les 5 Flux :</strong> <br> Nous gérons efficacement les flux de papier et carton, plastique PET, bio-déchets, verre et DIB, assurant une valorisation maximale de vos matières recyclables.</p>
                            </div>
                            <div class="about-style-content">

                                <p> <strong>Traçabilité et Qualité de Service :</strong> <br>Suivez chaque étape de la collecte et du traitement de vos déchets grâce à notre système de traçabilité en temps réel.</p>
                            </div>
                            <div class="about-style-content">
                                <p><strong>Zone d'Intervention :</strong> <br>Nos services sont disponibles dans toute l'Île-de-France, garantissant une collecte rapide et efficace où que vous soyez.</p>
                            </div>
                            <div class="about-style-content">
                                <p> <strong>Engagement Zéro Déchet :</strong> <br>Nous nous engageons à vous accompagner vers une gestion des déchets plus responsable, avec un objectif de réduction maximale des déchets résiduels.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="buying-home-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-5 col-md-12">
                <div class="buying-home-image">
                    <img src="assets/img/more-home/buying-home/buying-home.jpg" alt="image">

                    <div class="counter-box">
                        <span>DMT</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="buying-home-content">
                    <h3>Comment Ça Marche ?</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Remplissez le Formulaire de Devis</h4>
                                <p> En quelques clics, complétez les informations nécessaires pour obtenir votre devis personnalisé.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4> Recevez Votre Devis</h4>
                                <p>   Recevez immédiatement une estimation détaillée de nos services adaptés à vos besoins.

                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Planifiez Votre Collecte </h4>
                                <p>   Une fois le devis accepté, planifiez la date de collecte qui vous convient.

                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Profitez de Nos Services </h4>
                                <p>   Laissez-nous gérer la collecte et le traitement de vos déchets en toute sérénité.

                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
