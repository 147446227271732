// src/app/app.module.ts
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { ProfessionnalComponent } from './pages/professionnal/professionnal.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ActualityComponent } from './pages/actuality/actuality.component';
import { TruncatePipe } from './core/pipes/truncate.pipe';
import { MaterielProposeComponent } from './pages/materiel-propose/materiel-propose.component';
import { ReglementationsComponent } from './pages/reglementations/reglementations.component';
import { DemanderUneCollecteComponent } from './demander-une-collecte/demander-une-collecte.component';
import { FaireUneCollecteComponent } from './faire-une-collecte/faire-une-collecte.component';
import { MatiereComponent } from './matiere/matiere.component';
import { VolumeService } from './services/volume.service';
import { DemandeDeDevisComponent } from './demande-de-devis/demande-de-devis.component';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { DemanderUneCollecteChantierComponent } from './demander-une-collecte-chantier/demander-une-collecte-chantier.component';
import { DemanderUneCollecteIndustrieComponent } from './demander-une-collecte-industrie/demander-une-collecte-industrie.component';
import { CartonArticleComponent } from './carton-article/carton-article.component';
import { PlastiqueComponent } from './plastique/plastique.component';
import { CurageComponent } from './curage/curage.component';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeComponent,
        ProfessionnalComponent,
        ActivitiesComponent,
        NavbarComponent,
        FaqComponent,
        ContactComponent,
        ActualityComponent,
        TruncatePipe,
        MaterielProposeComponent,
        ReglementationsComponent,
        DemanderUneCollecteComponent,
        FaireUneCollecteComponent,
        MatiereComponent,
        DemandeDeDevisComponent,
        AddressAutocompleteComponent,
        DemanderUneCollecteChantierComponent,
        DemanderUneCollecteIndustrieComponent,
        CartonArticleComponent,
        PlastiqueComponent,
        CurageComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    providers: [VolumeService],
    bootstrap: [AppComponent]
})
export class AppModule { }
