import { Component, ViewChild, ElementRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
    @ViewChild('servicesLink') servicesLink: ElementRef;

    constructor(private viewportScroller: ViewportScroller) {}

    scrollToServices(): void {
        // Assurez-vous que l'élément cible a un ID correspondant
        this.viewportScroller.scrollToAnchor('services-section');
    }
}
