import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../core/services/shared.service'; // Importation du SharedService
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    form: FormGroup; // L'objet FormGroup représente le formulaire.
    loading = false;
    successMessage: string;

    constructor(
        private sharedService: SharedService, // Injection du SharedService
        private fb: FormBuilder // Injection du FormBuilder pour les formulaires réactifs
    ) {}

    ngOnInit(): void {
        // Initialisation du formulaire avec des contrôles et des validateurs.
        this.form = this.fb.group({
            name: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            message: new FormControl('', [Validators.required, Validators.minLength(10)]),
            phone: new FormControl('', []),
            subject: new FormControl('', [])
        });
    }

    get name() {
        return this.form.get('name');
    }

    get email() {
        return this.form.get('email');
    }

    get message() {
        return this.form.get('message');
    }

    sendMessage() {
        if (!this.form.valid) {
            return;
        }

        this.loading = true;
        // Appel de la méthode contact du SharedService pour envoyer les données du formulaire au backend.
        this.sharedService.contact(this.form.value).pipe(
            finalize(() => this.loading = false)
        ).subscribe({
            next: response => {
                this.successMessage = 'Votre message est envoyé avec succès.';
                setTimeout(() => {
                    this.successMessage = undefined;
                }, 5000);
            },
            error: errors => {
                console.log(errors);
            }
        });
    }
}
