<div class="activity-home-area-2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choice-home-content">
                    <h1>Papier</h1>
                    <p class="text-white">
                        DMT Recyclage est une entreprise spécialisée dans la gestion, le tri, et le recyclage des déchets papier.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">
    <section id="papier">
        <h2 class="section-title">📄 Qu'est-ce que le papier ?</h2>
        <p>
            Le papier est un matériau mince produit à partir de fibres végétales, principalement de la pulpe de bois, qui sont pressées et séchées. Il est largement utilisé dans les bureaux pour l'impression, l'écriture et l'emballage.
        </p>
    </section>
    <section id="utilisation-papier">
        <h2 class="section-title">🏢 Pourquoi le papier est-il utilisé ?</h2>
        <p>
            Le papier est utilisé pour sa polyvalence, sa facilité d'utilisation et son coût abordable. Il est essentiel dans les activités de bureau pour les documents, les formulaires, les brochures, les notes et autres supports imprimés.
        </p>
    </section>
    <section id="quantite-papier">
        <h2 class="section-title">📊 Quantité et sources de papier dans l'entreprise</h2>
        <p>
            Les entreprises génèrent des quantités importantes de papier à travers les documents imprimés, les correspondances, les emballages et divers autres usages. La gestion efficace de ces déchets est cruciale pour réduire l'impact environnemental.
        </p>
    </section>
    <section id="stockage-papier">
        <h2 class="section-title">🗑️ Moyens de stockage et de collecte du papier</h2>
        <p>
            Une gestion efficace du papier commence par un stockage approprié. DMT Recyclage propose divers contenants adaptés : des bacs de 240 à 1000 litres, des caisses-palettes de 600 litres, et des compacteurs de 20 à 30m³. Ces équipements facilitent le tri et le stockage du papier en attendant la collecte.
        </p>
    </section>
    <section id="collecte-papier">
        <h2 class="section-title">🚛 Procédure de collecte</h2>
        <p>
            La collecte du papier doit être régulière pour éviter l'accumulation et les problèmes de stockage. La fréquence de collecte dépend de la quantité de déchets produits par l'entreprise. Une collecte régulière garantit un environnement de travail propre et prépare le papier pour le recyclage.
        </p>
    </section>

    <section id="tri-papier">
        <h2 class="section-title">🔄 Systèmes de tri et préparation au recyclage</h2>
        <p>
            Le tri sélectif du papier est essentiel pour garantir un recyclage efficace. Les types de papier doivent être séparés, tels que les papiers de bureau, les journaux, les magazines, les cartons fins, etc. Un affichage clair des consignes de tri et une formation appropriée des employés sur les méthodes de tri sélectif sont essentiels.
        </p>
    </section>

    <section id="compaction-papier">
        <h2 class="section-title">⚙️ Matériel de compaction</h2>
        <p>
            Pour gérer les volumes importants de papier, la location de matériel de compaction est souvent nécessaire. DMT Recyclage propose des compacteurs de 20 à 30m³ et des presses à balles qui permettent de réduire considérablement le volume des déchets papier, facilitant ainsi leur transport et leur recyclage. Le fait de plier systématiquement les cartons permet de diminuer le volume des déchets et de réduire les coûts de collecte.
        </p>
    </section>

    <section id="recyclage-papier">
        <h2 class="section-title">♻️ Traitement final, recyclage et réemploi du papier</h2>
        <p>
            Une fois collecté et trié, le papier est transporté vers des centres de recyclage. Le processus de traitement inclut le tri, le nettoyage, la réduction en pulpe et la refabrication en nouveaux produits papier. Le recyclage du papier permet de réduire la consommation de ressources naturelles et de diminuer l'empreinte carbone.
        </p>
    </section>
    <section id="action-zero-dechet">
        <h2 class="section-title">🌱 Action zéro déchet</h2>
        <p>
            Pour aller plus loin dans une démarche zéro déchet, les entreprises peuvent exiger de leurs fournisseurs de reprendre les emballages en carton ou d'utiliser des solutions d'emballage réutilisables. Il est également recommandé de réduire l'utilisation de papier en passant à des solutions numériques quand cela est possible.
        </p>
    </section>
    <div class="row">
        <div class="col-lg-3">
            <a routerLink="/demander-une-collecte"
               fragment="contant-form"
               class="cmn-banner-btn d-block mt-3 btn-success btn-sm"><i
                class="bx bx-note"></i>Demander un collecte</a>
        </div>
    </div>

</div>
