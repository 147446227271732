import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ActivitiesComponent } from "./pages/activities/activities.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { HomeComponent } from "./pages/home/home.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { ActualityComponent } from "./pages/actuality/actuality.component";
import { MaterielProposeComponent } from "./pages/materiel-propose/materiel-propose.component";
import { ReglementationsComponent } from "./pages/reglementations/reglementations.component";
import { DemanderUneCollecteComponent } from './demander-une-collecte/demander-une-collecte.component';
import { FaireUneCollecteComponent } from './faire-une-collecte/faire-une-collecte.component';
import { MatiereComponent } from './matiere/matiere.component';
import { DemandeDeDevisComponent } from './demande-de-devis/demande-de-devis.component';
import {
    DemanderUneCollecteChantierComponent
} from "./demander-une-collecte-chantier/demander-une-collecte-chantier.component";
import {
    DemanderUneCollecteIndustrieComponent
} from "./demander-une-collecte-industrie/demander-une-collecte-industrie.component";

import { CartonArticleComponent } from './carton-article/carton-article.component';

import { PlastiqueComponent } from './plastique/plastique.component';
import { CurageComponent } from './curage/curage.component';
const routes: Routes = [
    { path: "", component: HomeComponent, runGuardsAndResolvers: "always" },
    {
        path: "services",
        component: ActivitiesComponent,
        runGuardsAndResolvers: "always",
    },
    {
        path: "materiel-propose",
        component: MaterielProposeComponent,
    },
    {
        path: "reglementations",
        component: ReglementationsComponent,
    },
    {
        path: "faq",
        component: FaqComponent,
    },
    {
        path: "contact",
        component: ContactComponent,
    },
    {
        path: "actuality",
        component: ActualityComponent,
    },
    {
        path: 'demander-une-collecte',
        component: DemanderUneCollecteComponent
    },
    {
        path: 'faire-une-collecte',
        component: FaireUneCollecteComponent
    },
    {
        path: 'matieres',
        component: MatiereComponent
    },
    {
        path: 'demande-de-devis',
        component: DemandeDeDevisComponent
    },
    {
        path: 'demander-une-collecte-chantier',
        component: DemanderUneCollecteChantierComponent
    },
    {
        path: 'demander-une-collecte-industrie',
        component: DemanderUneCollecteIndustrieComponent
    },
    { path: 'carton', component: CartonArticleComponent },
    { path: 'plastique', component: PlastiqueComponent },
    { path: 'curage', component: CurageComponent },


];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
